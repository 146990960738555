@use 'assets/scss/colors';

.header {
	min-height: 68px;
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;

	position: sticky;
	top: 0;
	width: 100%;
	margin-bottom: 20px;
	z-index: 13;

	&__top {
		display: flex;
		padding: 0 20px;
		background-color: colors.$bg-block;

		border-bottom: 1px solid colors.$line-line;
		position: relative;
		z-index: 2;
	}

	&__menu-icon {
		display: none;
	}

	&__tribuna-logo {
		display: flex;
		align-items: center;
		gap: 40px;
		max-width: 190px;
		height: 30px;

		margin-top: 20px;
		margin-right: 115px;

		&__logo {
			display: flex;
			align-items: flex-end;
			height: 26px;
		}

		&__icon {
			margin-right: 5px;
		}
		&__text {
			fill: colors.$icons-primary;
		}
		&__seo {
			position: absolute;
			visibility: hidden;
		}
	}

	&__top-sections {
		display: flex;
		position: relative;
		flex: 1 1;
		height: 68px;
		align-items: center;
	}

	&__menu-bonus {
		display: flex;
		align-items: center;
	}

	&__empty-line {
		display: none;
	}

	&__dropdown-wrapper {
		display: flex;
		align-items: center;
	}

	&__right-buttons-wrapper {
		display: flex;
		align-items: center;
	}

	&__theme-button-wrapper {
		display: flex;
		align-items: center;
	}

	&__login-button-wrapper {
		display: flex;
		align-items: center;
		margin-left: 10px;

		cursor: pointer;
	}

	&__bottom {
		display: flex;
		background-color: colors.$bg-block;
		padding: 0 20px;
		align-items: center;
		justify-content: space-between;

		gap: 30px;
		min-height: 48px;
		position: relative;
		z-index: 1;
		top: 0;
		margin-bottom: 0;
		transition:
			top 0.5s,
			margin-bottom 0.5s;
		will-change: margin-bottom;

		&--scroll-down {
			top: -48px;
			margin-bottom: -48px;
		}
	}

	&__bottom-item {
		white-space: nowrap;
		display: flex;
		height: 48px;
		align-items: center;
		color: colors.$text-secondary;
	}

	&__bottom-item-wrapper {
		position: relative;

		&:hover {
			.header__bottom-item-dropdown {
				display: flex;
			}
		}
	}

	&__bottom-item-dropdown {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
	}
}

@media (min-width: 1025px) {
	.header {
		&__bottom-item {
			&:hover {
				color: colors.$text-link-active;
			}
		}
	}
}

@media (max-width: 1024px) {
	.header {
		min-height: 68px;
		border-bottom: 1px solid colors.$line-line;

		&__top {
			padding: 20px 15px 0;
			border: none;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&__menu-icon {
			order: 2;
			margin: 5px 0 20px 0;
			display: block;

			svg {
				fill: colors.$icons-primary;
			}
		}

		&__tribuna-logo {
			order: 0;
			margin: 0 0 20px 0;
			display: flex;
			justify-content: flex-start;

			&__logo {
				align-items: center;
			}
		}

		&__menu-bonus {
			order: 1;
			margin-bottom: 20px;
		}

		&__top-sections {
			order: 6;
			width: 100%;
			gap: 0;
			align-items: flex-start;
			min-width: 100%;
			flex-direction: column;
			height: auto;
			border-top: 1px solid colors.$line-line;
			z-index: 1;

			&--closed {
				display: none;
			}
		}

		&__right-buttons-wrapper {
			order: 5;
			&--closed {
				display: none;
			}
		}

		&__login-button-wrapper {
			margin: 20px 0 20px 20px;
			order: 5;
			align-items: flex-start;

			&--closed {
				display: none;
			}
		}

		&__theme-button-wrapper {
			margin: 20px 0;
			order: 4;
			align-items: flex-start;

			&--closed {
				display: none;
			}

			div[data-test='theme-selector'] {
				margin: 0;
			}
		}

		&__empty-line {
			display: block;
			order: 3;
			height: 1px;
			width: 100%;
			background-color: colors.$line-line;

			&--closed {
				display: none;
			}
		}

		&__dropdown-wrapper {
			order: 5;
			align-items: flex-start;
			margin: 20px 0;

			max-width: 60px;
			min-width: 60px;

			&--closed {
				display: none;
			}
		}

		&__bottom {
			padding: 0;
			flex-direction: column;
			border: none;
			gap: 0;

			align-items: inherit;
			justify-content: center;

			&--closed {
				display: none;
			}
		}

		&__bottom-item {
			width: 100%;
			height: auto;
			display: block;
			padding: 15px;
			border-bottom: 1px solid colors.$line-line;
		}

		&__bottom-item-wrapper:hover {
			.header__bottom-item-dropdown {
				display: none;
			}
		}
	}
}
